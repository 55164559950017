<template>
  <v-layout fill-height align-center>
    <v-row>
      <v-col cols="10" md="6" lg="3" class="offset-1 offset-md-3 offset-lg-8">
        <v-card class="elevation-12 login-card" light>
          <v-card-title class="primary--text justify-center">{{ projectTitle }}</v-card-title>
          <ValidationObserver ref="observerLogin">
            <v-form ref="form" @submit="handleLogin">
              <v-card-text>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.email')" rules="required|email" v-slot="{ errors }">
                  <VAppTextField
                    v-model="authObj.email"
                    name="email"
                    prepend-inner-icon="mdi-account"
                    :label="$vuetify.lang.t('$vuetify.auth.email')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.password')" rules="required" v-slot="{ errors }">
                  <VAppTextField
                    v-model="authObj.password"
                    type="password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    :label="$vuetify.lang.t('$vuetify.auth.password')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'Forgot' }" color="primary" text large>
                  {{ $vuetify.lang.t("$vuetify.auth.forgot") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" depressed right large>
                  {{ $vuetify.lang.t("$vuetify.buttons.login") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
          <v-card-text class="pa-0">
            <TextDivider :label="$vuetify.lang.t('$vuetify.labels.or')"></TextDivider>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="{ name: 'CreateUser' }" color="primary" width="100%" large text exact>
              <v-icon>mdi-account-plus</v-icon>{{ $vuetify.lang.t("$vuetify.auth.createUser") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import authenticationApi from "@/api/authentication";
import TextDivider from "@/components/layout/TextDivider";
export default {
  name: "Login",
  components: { TextDivider },
  metaInfo: {
    title: "Entrar",
  },
  data: () => ({
    authObj: {
      email: "",
      password: "",
      remember: false,
    },
  }),
  computed: {
    projectTitle: () => {
      return process.env.VUE_APP_TITLE;
    },
  },
  methods: {
    // Login function
    handleLogin(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.observerLogin.validate().then((success) => {
        if (success) {
          authenticationApi
            .authenticate(this.authObj)
            .then(() => {
              // Hide Overlay
              this.$emit("control-overlay", false);
              // Redirect User to Dashboard
              this.$router.push({ name: "Dashboard" });
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.authentication.errors.authenticate"),
                text: error.message,
              });
            })
            .finally(() => {
              // Hide Overlay
              this.$emit("control-overlay", false);
            });
        } else {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverLogin.title"),
            text: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverLogin.message"),
          });
          // Hide Overlay
          this.$emit("control-overlay", false);
        }
      });
    },
  },
};
</script>
