import HttpClient from "@/services/httpClient.js";
const apiPath = "/emailConfiguration/";
export default {
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
  getByCode(code) {
    if (!code) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + code,
    }).then((response) => response.data);
  },
  save(code, value) {
    if (!code) return Promise.reject({ message: "error" });
    if (!value) return Promise.reject({ message: "error" });
    let configurationObj = {
      code: code,
      value: value,
    };
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + code,
      data: configurationObj,
    }).then((response) => response.data);
  },
};
