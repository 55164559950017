import AuthTemplate from "@/views/auth/Template";
import Login from "@/views/auth/Login";
import Forgot from "@/views/auth/Forgot";
import Reset from "@/views/auth/Reset";
import CreateUser from "@/views/auth/CreateUser";
import ValidateUser from "@/views/auth/ValidateUser";
import DefaultTemplate from "@/views/DefaultTemplate";
import Dashboard from "@/views/Dashboard";
import SiteConfiguration from "@/views/configuration/Configuration";
import ListUsers from "@/views/users/List";
import RegisterUsers from "@/views/users/Register";
import MyAccount from "@/views/users/MyAccount";
import RegisterServices from "@/views/services/Register";
import ViewProcess from "@/views/process/View";

const routes = [
  {
    path: "/u/validar/:token",
    name: "ValidateUser",
    component: ValidateUser,
    meta: {
      OnlyLoggedOut: true,
    },
  },
  {
    path: "/entrar",
    component: AuthTemplate,
    meta: {
      OnlyLoggedOut: true,
    },
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
      },
      {
        path: "esqueci",
        name: "Forgot",
        component: Forgot,
      },
      {
        path: "novo-usuario",
        name: "CreateUser",
        component: CreateUser,
      },
      {
        path: "reset/:token",
        name: "Reset",
        component: Reset,
      },
    ],
  },
  {
    path: "/",
    component: DefaultTemplate,
    meta: {
      OnlyLogged: true, // true
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        props: (route) => ({
          activeTab: route.query.at,
        }),
      },
      {
        path: "configuration",
        name: "Configuration",
        component: SiteConfiguration,
        props: (route) => ({
          activeTab: route.query.at ? route.query.at : null,
        }),
      },
      {
        path: "usuarios",
        name: "ListUsers",
        component: ListUsers,
      },
      {
        path: "usuarios/f/:id?",
        name: "RegisterUsers",
        component: RegisterUsers,
      },
      {
        path: "minha-conta",
        name: "MyAccount",
        component: MyAccount,
      },
      {
        path: "services/f/:id?",
        name: "RegisterServices",
        component: RegisterServices,
      },
      {
        path: "process/v/:id?",
        name: "ViewProcess",
        component: ViewProcess,
        props: (route) => ({
          titleLabel: route.query.l,
          action: route.query.a,
          activeTab: route.query.at,
        }),
      },
    ],
  },
];
export default routes;
