/* eslint-disable */
export default {
  // Custom Translations
  api: {
    authentication: {
      errors: {
        authenticate: "Erro na autenticação",
        forgot: "Erro na requisição",
        reset: "Erro na requisição",
      },
    },
    user: {
      errors: {
        create: "Erro ao criar usuário",
        update: "Erro ao atualizar usuário",
        validate: "Erro ao validar usuário",
        get: "Erro ao buscar usuários",
        getById: "Erro ao buscar o usuário",
      },
    },
    services: {
      errors: {
        create: "Erro ao criar serviço",
        update: "Erro ao atualizar serviço",
        validate: "Erro ao validar serviço",
        get: "Erro ao buscar serviços",
        getById: "Erro ao buscar o serviço",
      },
    },
    lecom: {
      errors: {
        getToken: "Erro ao buscar o Token de acesso",
        getModels: "Erro ao buscar modelos",
        getService: "Erro ao buscar modelos",
        getPending: "Erro ao buscar processos pendentes",
        getDone: "Erro ao buscar processos encerrados",
        getForm: "Erro ao buscar formulário",
        getProgress: "Erro ao buscar processos em andamento",
        postOpenProcess: "Erro ao criar novo processo",
      },
    },
    portal: {
      errors: {
        getToken: "Erro ao buscar o Token de acesso",
        initProcess: "Erro ao iniciar processo",
        getProcessInstance: "Erro ao buscar a instância do processo",
      },
    },
    layoutConfiguration: {
      errors: {
        get: "Erro nas configurações de layout",
        primary: "Erro ao atualizar a cor primária",
        secondary: "Erro ao atualizar a cor primária",
        logoImage: "Erro ao atualizar a imagem da logo",
        bannerImage: "Erro ao atualizar a imagem do banner",
      },
    },
    emailConfiguration: {
      errors: {
        get: "Erro nas configurações de e-mail",
        smtpServer: "Erro ao atualizar o Servidor SMTP",
        smtpPort: "Erro ao atualizar a Porta SMTP",
        smtpEmail: "Erro ao atualizar o E-mail SMTP",
        smtpPassword: "Erro ao atualizar a Senha SMTP",
        fromEmail: "Erro ao atualizar o E-mail do remetente",
        useTls: "Erro ao atualizar o uso do SSL/TLS",
      },
    },
    contentConfiguration: {
      errors: {
        get: "Erro nas configurações de conteúdo",
        termsCondition: "Erro ao atualizar os termos e condições",
      },
    },
  },
  process: {
    register: "Iniciar Processo",
    view: "Visualizar Processo",
    answer: "Responder Processo",
    fields: {
      processInstanceId: "Num do Processo", 
      service: "Serviço", 
      nameActivity: "Etapa", 
      deadline: "Prazo", 
      user: "Responsável", 
      createdAt: "Data de Criação", 
      notes: "Observações", 
    },
  },
  dashboard: {
    title: "Dashboard",
    processConsultation: "Consulta do processo",
    sendInvoice: "Envio de nota fiscal",
    sections: {
      dashboard: {
        title: "Dashboard",
        contentTitle: "Outros sistemas",
        noContentsAvailable: "Não há sistemas disponíveis.",
      },
      newRequest: {
        title: "Nova Solicitação",
        openRequest: "Abrir nova solicitação",
        financings: {
          title: "Financeiro",
          sendInvoice: "Envio de nota fiscal",
          anticipatePayment: "Envio de nota fiscal",
          sendCopyPaymentSlip: "Envio de segunda via de boleto",
        },
        buy: {
          title: "Compras",
          buyQuotation: "Cotação de compras",
          divergentDelivery: "Entrega divergente",
          contractRenewal: "Renovação de contrato",
        },
        registrationCenter: {
          title: "Central de Cadastros",
          visitScheduling: "Programação de visitas",
          documentSending: "Envio de documentos",
          userRegistration: "Cadastro de novo usuário",
        },
      },
      myPendings: {
        title: "Minhas Pendências",
        contentTitle: "Minhas Pendências",
        detailCardTitle: "Leitura Rápida",
        buttonLabel: "Responder Processo",
      },
      inProgressRequests: {
        title: "Solicitações em Andamento",
        detailCardTitle: "Consulta do processo",
        contentTitle: "Minhas Solicitações em Andamento",
        buttonLabel: "Visualizar Processo",
      },
      closeRequests: {
        title: "Solicitações Encerradas",
        detailCardTitle: "Consulta do processo",
        contentTitle: "Minhas Solicitações Encerradas",
        buttonLabel: "Visualizar Processo",
      },
      notifications: {
        title: "Notifications",
        myNotifications: "Minhas Notificações",
      },
    },
  },
  auth: {
    email: "E-mail",
    password: "Senha",
    remember: "Manter conectado",
    logout: "Desconectar-se",
    forgot: "Esqueci minha senha",
    backToLogin: "Ir para Login",
    forgotSubTitle: "Será enviado um e-mail com o link para a geração de uma nova senha.",
    resetSubTitle: "Por favor, digite abaixo uma nova senha. A senha deve conter ao menos 8 caracteres, uma letra, um número e um caracter especial",
    createUser: "Criar um novo usuário",
    notifications: {
      errors: {
        formObserverLogin: {
          title: "Dados insuficientes",
          message: "E-mail e/ou senha não foram informados ou foram preenchidos incorretamente. Por favor, verifique antes de continuar.",
        },
        formObserverForgot: {
          title: "Dados insuficientes",
          message: "E-mail inválido. Por favor, verifique antes de continuar.",
        },
        formObserverReset: {
          title: "Dados insuficientes",
          message: "Senha inválida. Por favor, verifique antes de continuar.",
        },
      },
      success: {
        forgotReset: {
          title: "E-mail enviado com sucesso!",
          message: "Foi enviado para seu e-mail o link para a recuperação da senha. Por gentileza, verifique seu e-mail.",
        },
        resetPassword: {
          title: "Senha alterada com sucesso!",
          message: "A sua senha foi alterada com sucesso.",
        },
      },
    },
  },
  user: {
    title: "Usuário",
    titlePl: "Usuários",
    termsAndConditionsAccept: "Eu li e concordo com os",
    termsAndConditions: "Termos e Condições",
    updateUser: "Atualizar Usuário",
    createUser: "Criar novo Usuário",
    editUser: "Editar Usuário",
    removeUser: "Suspender Usuário",
    enableUser: "Ativar Usuário",
    backToList: "Voltar para Lista",
    myAccount: "Minha Conta",
    approveUser: "Aprovar Usuário",
    permissions: "Permissões",
    approveDialogContent: "Tem certeza que deseja aprovar o usuário <b>{0}</b>?",
    removeDialogContent: "Tem certeza que deseja suspender o usuário <b>{0}</b>?",
    setPermissionsContent: "Selecione os serviços que serão habilitados para o usuário <b class='ml-1'>{0}</b>:",
    lastLogin: "Último login",
    fields: {
      name: "Nome",
      email: "E-mail",
      cpf: "CPF",
      password: "Senha",
      phone: "Telefone",
      businessName: "Nome do Fornecedor",
      businessCnpj: "CNPJ do Fornecedor",
      status: "Status",
      admin: "Administrador",
    },
    status: {
      USU_ATIVO: 'Ativo',
      USU_BLOQUEADO: 'Bloqueado',
      USU_PENDENTE: 'Pendente',
      USU_PENDENTE_SENHA: 'Pendente Senha',
      USU_SUSPENSO: 'Suspenso',
      USU_PENDENTE_APROVACAO: 'Pendente de Aprovação',
    },
    notifications: {
      errors: {
        formObserverUser: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
      },
      success: {
        createUser: {
          title: "Usuário criado com sucesso!",
          message: "Foi enviado por e-mail o link para validar a criação do usuário. É necessário acessar o e-mail cadastrado e validar para ter acesso à ferramenta.",
        },
        updateUser: {
          title: "Usuário atualizado com sucesso!",
          message: "Usuário atualizado com sucesso.",
        },
        validateUser: {
          title: "Usuário validado com sucesso!",
          message: "O usuário foi validado com sucesso. Faça o login para acessar a ferramenta.",
        },
        removeUser: {
          title: "Usuário suspenso com sucesso!",
          message: "O usuário foi suspenso com sucesso.",
        },
        approveUser: {
          title: "Usuário aprovado com sucesso!",
          message: "O usuário foi aprovado com sucesso.",
        },
        saveUserPermissions: {
          title: "Permissões atualizadas com sucesso!",
          message: "As permissões foram atribuídas ao usuário com sucesso.",
        },
      },
    },
  },
  services: {
    title: "Serviços",
    addNewService: "Adicionar novo serviço",
    editService:"Editar serviço",
    disableService:"Desativar serviço",
    enableService:"Ativar serviço",
    updateService:"Atualizar serviço",
    createService:"Criar serviço",
    listModels:"Lista de modelos",
    noServicesAvailable: "Não há serviços disponíveis.",
    iconHelper: "Escolha um ícone pelo site do <a href=\"https://materialdesignicons.com/\" rel=\"noopener noreferrer\" target=\"_blank\">Material Design</a> e preencha com o nome do ícone.",
    fields: {
      title: "Título",
      category: "Categoria",
      resume: "Descrição",
      active: "Ativo",
      model: "Modelo",
      icon: "Ícone",
    },
    notifications: {
      errors: {
        formObserverService: {
          title: "Dados insuficientes",
          message: "Verifique se todos os campos do formulário foram preenchidos corretamente antes de continuar.",
        },
      },
      success: {
        createService: {
          title: "Serviço criado com sucesso!",
          message: "Serviço criado com sucesso.",
        },
        updateService: {
          title: "Serviço atualizado com sucesso!",
          message: "Serviço atualizado com sucesso.",
        },
      },
    },
  },
  configuration: {
    title: "Configuração",
    titlePl: "Configurações",
    layoutConfiguration: {
      title: "Leiaute",
      colors: "Cores",
      images: "Imagens",
      primary: "Cor primária",
      secondary: "Cor secundária",
      logoImage: "Imagem da Logo",
      logoImageHelper: "<b>Dimensões recomendadas:</b> 190x60<br><b>Tamanho máximo do arquivo:</b> {0}Mb<br><b>Extensões permitidas:</b> jpg, jpeg, png",
      bannerImage: "Imagem do Banner",
      bannerImageHelper: "<b>Dimensões recomendadas:</b> 1920x1080<br><b>Tamanho máximo do arquivo:</b> {0}Mb<br><b>Extensões permitidas:</b> jpg, jpeg, png",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações de leiaute atualizadas com sucesso! Aguarde a página ser recarregada para visualizar as alterações.",
        },
      },
    },
    emailConfiguration: {
      title: "E-mail",
      smtpServer: "Servidor",
      smtpPort: "Porta",
      smtpEmail: "E-mail",
      smtpPassword: "Senha",
      fromEmail: "E-mail do remetente",
      useTls: "SSL/TLS",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações de e-mail atualizadas com sucesso!",
        },
      },
    },
    contentConfiguration: {
      title: "Conteúdo",
      termsConditions: "Termos e Condições",
      notifications: {
        success: {
          title: "Configuração atualizada com sucesso!",
          message: "Configurações de conteúdo atualizadas com sucesso!",
        },
      },
    },
  },
  fileUpload: {
    fileTypeNotAllowed: "Tipo do arquivo inválido.",
    fileSizeNotAllowed: "Tamanho do arquivo excede o permitido.",
    notifications: {
      errors: {
        fileSizeError: {
          title: "Tamanho do arquivo excedido!",
          message: "O arquivo {0} excedeu o tamanho máximo permitido de {1}Mb.",
        },
        fileExtensionError: {
          title: "Tipo de arquivo não permitio!",
          message: "A extensão do arquivo {0} não é permitida.",
        },
      },
    },
  },
  buttons: {
    login: "Entrar",
    back: "Voltar",
    send: "Enviar",
    create: "Criar",
    close: "Fechar",
    save: "Salvar",
    backToDashboard: "Ir para o Dashboard",
    addProcess: "Iniciar Processo",
    answer: "Responder",
    cancel: "Cancelar",
    approve: "Aprovar",
    suspend: "Suspender",
  },
  labels: {
    or: "Ou",
    confirmPassword: "Confirmar Senha",
    clickToSelectAFile: "Clique para selecionar um arquivo...",
    actions: "Ações",
    help: "Ajuda",
  },
  badge: 'Distintivo',
  close: 'Fechar',
  dataIterator: {
    noResultsText: 'Nenhum dado encontrado',
    loadingText: 'Carregando itens...'
  },
  dataTable: {
    itemsPerPageText: 'Linhas por página:',
    ariaLabel: {
      sortDescending: 'Ordenado decrescente.',
      sortAscending: 'Ordenado crescente.',
      sortNone: 'Não ordenado.',
      activateNone: 'Ative para remover a ordenação.',
      activateDescending: 'Ative para ordenar decrescente.',
      activateAscending: 'Ative para ordenar crescente.'
    },
    sortBy: 'Ordenar por'
  },
  dataFooter: {
    itemsPerPageText: 'Itens por página:',
    itemsPerPageAll: 'Todos',
    nextPage: 'Próxima página',
    prevPage: 'Página anterior',
    firstPage: 'Primeira página',
    lastPage: 'Última página',
    pageText: '{0}-{1} de {2}'
  },
  datePicker: {
    itemsSelected: '{0} selecionado(s)',
    nextMonthAriaLabel: 'Próximo mês',
    nextYearAriaLabel: 'Próximo ano',
    prevMonthAriaLabel: 'Mês anterior',
    prevYearAriaLabel: 'Ano anterior'
  },
  noDataText: 'Não há dados disponíveis',
  carousel: {
    prev: 'Visão anterior',
    next: 'Próxima visão',
    ariaLabel: {
      delimiter: 'Slide {0} de {1} do carrossel'
    }
  },
  calendar: {
    moreEvents: 'Mais {0}'
  },
  fileInput: {
    counter: '{0} arquivo(s)',
    counterSize: '{0} arquivo(s) ({1} no total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Navegação de paginação',
      next: 'Próxima página',
      previous: 'Página anterior',
      page: 'Ir à página {0}',
      currentPage: 'Página atual, página {0}'
    }
  },
  rating: {
    ariaLabel: {
      icon: 'Rating {0} de {1}'
    }
  }
};
