<template>
  <ValidationObserver ref="observerService">
    <v-row class="my-0">
      <v-col cols="12" md="6">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.services.fields.title')" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="serviceObj.title"
            name="title"
            prepend-inner-icon="mdi-account"
            :label="$vuetify.lang.t('$vuetify.services.fields.title')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader v-if="!listCategories" class="pt-7" height="70" type="text"></v-skeleton-loader>
        <ValidationProvider v-else :name="$vuetify.lang.t('$vuetify.services.fields.category')" rules="required" v-slot="{ errors }">
          <VAppSelectField
            v-model="serviceObj.category"
            name="category"
            :items="listCategories"
            item-text="description"
            :label="$vuetify.lang.t('$vuetify.services.fields.category')"
            :error-messages="errors"
            return-object
          ></VAppSelectField>
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.services.fields.resume')" rules="required" v-slot="{ errors }">
          <VAppTextArea
            v-model="serviceObj.resume"
            name="resume"
            :label="$vuetify.lang.t('$vuetify.services.fields.resume')"
            :error-messages="errors"
          ></VAppTextArea>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader v-if="!listModels" type="text"></v-skeleton-loader>
        <ValidationProvider v-else :name="$vuetify.lang.t('$vuetify.services.fields.model')" rules="required" v-slot="{ errors }">
          <VAppSelectField
            v-model="selectedCodForm"
            name="model"
            :items="listModels"
            item-value="codForm"
            item-text="nomeForm"
            :label="$vuetify.lang.t('$vuetify.services.fields.model')"
            :error-messages="errors"
          ></VAppSelectField>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <VAppTextField
          v-model="serviceObj.icon"
          name="icon"
          :prepend-inner-icon="'mdi-' + serviceObj.icon"
          :label="$vuetify.lang.t('$vuetify.services.fields.icon')"
          hide-details
        ></VAppTextField>
        <span v-html="$vuetify.lang.t('$vuetify.services.iconHelper')"></span>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="serviceObj.active"
          :true-value="1"
          :false-value="0"
          :label="$vuetify.lang.t('$vuetify.services.fields.active')"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import serviceApi from "@/api/service";
import categoryApi from "@/api/category";
import lecomApi from "@/api/lecom";
export default {
  name: "FormService",
  data: () => ({
    // Service Obj
    serviceObj: {
      title: "",
      category: {},
      active: 0,
      description: "",
      codFormLecom: null,
      codVersionLecom: null,
      titleFormLecom: null,
    },
    selectedCodForm: null,
    // List Categories
    listCategories: null,
    // List Categories
    listModels: null,
  }),
  created() {
    // Load Categories
    this.loadCategories();
    // Load Model List
    this.loadModelList();
  },
  methods: {
    loadCategories() {
      // Get Categories
      categoryApi
        .get()
        .then((response) => {
          this.listCategories = response;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        });
    },
    loadModelList() {
      // Get Lecom Models
      lecomApi
        .getService()
        .then((response) => {
          this.listModels = response.data;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getService"),
            text: error.message,
          });
        });
    },
    // Submit Function
    submitForm() {
      // Validate form
      this.$refs.observerService.validate().then((success) => {
        if (success) {
          // Handle Before Save
          this.handleModuleBeforeSave();
          // Set API Method
          let apiMethod = this.serviceObj.id ? "update" : "create";
          // Update Service
          serviceApi[apiMethod](this.serviceObj)
            .then((serviceObj) => {
              // Update Service Obj
              this.serviceObj = serviceObj;
              // Handle Model Obj
              this.handleModelObj();
              // Emit Success
              this.$emit("success", serviceObj);
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.services.errors." + this.serviceObj.id ? "update" : "create"),
                text: error.message,
              });
              // Emit Error
              this.$emit("error");
            });
        } else {
          // Notify Error
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.services.notifications.errors.formObserverService.title"),
            text: this.$vuetify.lang.t("$vuetify.services.notifications.errors.formObserverService.message"),
          });
          // Emit Error
          this.$emit("error");
        }
      });
    },
    setServiceObj(serviceObj) {
      this.serviceObj = serviceObj;
      this.handleModelObj();
    },
    handleModelObj() {
      this.selectedCodForm = String(this.serviceObj.codFormLecom);
    },
    handleModuleBeforeSave() {
      this.serviceObj.formLecom = this.listModels.find((element) => {
        return element.codForm === this.selectedCodForm;
      });
    },
  },
};
</script>
