var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"7"}},[_c('div',{staticClass:"text-h5 mt-4"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.sections." + _vm.section + ".contentTitle")))]),(!_vm.listItems)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"stripped-datatable mt-8",attrs:{"headers":_vm.tableHeader,"items":_vm.listItems,"items-per-page":15,"item-key":"processNo"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:{ 'primary--text' : _vm.selectedProcess === item },attrs:{"icon":""},on:{"click":function($event){return _vm.clickDashboardButton(item)}}},[_c('v-icon',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.icon ? _vm.icon : "mdi-magnify"))])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6","offset-xl":"1","lg":"5","xl":"4"}},[_c('v-card',{ref:"processCard"}),(_vm.selectedProcess)?_c('v-card',{staticClass:"mt-4 mb-2",attrs:{"color":"blue-grey lighten-5"}},[_c('v-card-title',{staticClass:"primary--text"},[(_vm.icon)?_c('v-icon',{staticClass:"primary--text mr-2"},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.sections." + _vm.section + ".detailCardTitle"))+" ")],1),_c('v-divider',{staticClass:"primary"}),_c('v-card-text',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":[
            { text: _vm.$vuetify.lang.t('$vuetify.dashboard.sections.' + _vm.section + '.title') },
            { text: _vm.selectedProcess.service },
            { text: _vm.selectedProcess.processInstanceId, class: 'font-weight-bold primary' } ]},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-forward")])]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:item.disabled ? 'grey--text' : 'deep-purple--text'},[_vm._v(_vm._s(item.text))])]}}],null,false,1954035565)}),_c('v-row',{staticClass:"py-1 mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3","xl":"2"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.process.fields.service")))]),_c('v-col',[_vm._v(_vm._s(_vm.selectedProcess.service))])],1),_c('v-row',{staticClass:"py-1 mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3","xl":"2"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.process.fields.nameActivity")))]),_c('v-col',[_vm._v(_vm._s(_vm.selectedProcess.nameActivity))])],1),_c('v-row',{staticClass:"py-1 mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3","xl":"2"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.process.fields.createdAt")))]),_c('v-col',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.selectedProcess.createdAt)))])],1),_c('v-row',{staticClass:"py-1 mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12","md":"3","xl":"2"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.process.fields.user")))]),_c('v-col',[_vm._v(_vm._s(_vm.selectedProcess.user))])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"to":{
            name: 'ViewProcess',
            params: { id: _vm.selectedProcess.processInstanceId },
            query: { l: _vm.formTitle, a: 'getForm', at: _vm.activeTab },
          },"color":"secondary"}},[(_vm.icon)?_c('v-icon',[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.sections." + _vm.section + ".buttonLabel"))+" ")],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }