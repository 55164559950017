<template>
  <div class="pa-4">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.user.titlePl") }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'RegisterUsers' }" color="primary" depressed right large>
          <v-icon>mdi-account-plus</v-icon>{{ $vuetify.lang.t("$vuetify.user.createUser") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" lg="11" xl="10">
        <v-data-table
          :headers="tableHeaders"
          :items="listUsers"
          :items-per-page="15"
          :server-items-length="totalRecords"
          :loading="loadingTable"
          :options="{ sortBy: ['email'], sortDesc: [false] }"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25, 50] }"
          class="stripped-datatable elevation-1"
          @update:options="loadUserTable"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :to="{ name: 'RegisterUsers', params: { id: item.id } }" v-bind="attrs" v-on="on" small icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.user.editUser") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.admin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" color="blue darken-2" @click="showPermissionDialog(item)" small icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-shield-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.user.permissions") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.status.code === 'USU_PENDENTE_APROVACAO' && loggedUser.admin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  color="green"
                  @click="
                    userConfirmation = item;
                    approveConfirmDialog = true;
                  "
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.user.approveUser") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else-if="item.status.code !== 'USU_SUSPENSO'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  color="red"
                  @click="
                    userConfirmation = item;
                    removeConfirmDialog = true;
                  "
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.user.removeUser") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.status.title="{ item }">
            {{ $vuetify.lang.t("$vuetify.user.status." + item.status.code) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="approveConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.approveUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.approveDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="approveConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="approveUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.approve") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.removeUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.removeDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="removeConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="red" text @click="removeUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.suspend") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="permissionsDialog" max-width="600" scrollable>
      <v-card v-if="userPermissions.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.permissions") }}</v-card-title>
        <v-card-text class="pt-4 content-scroll">
          <v-skeleton-loader v-if="!listServices" type="text@3"></v-skeleton-loader>
          <v-list flat subheader two-line v-else>
            <v-subheader v-html="$vuetify.lang.t('$vuetify.user.setPermissionsContent', userPermissions.name)"></v-subheader>
            <v-list-item-group v-model="selectedPermissions" multiple active-class="blue lighten-5 primary--text">
              <template v-for="(serviceObj, index) in listServices">
                <v-list-item :key="serviceObj.titleFormLecom" :value="serviceObj.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ serviceObj.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ serviceObj.titleFormLecom }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">
                        {{ active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listServices.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closePermissionDialog">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="savePermissions(userPermissions.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.v-btn .v-icon,
.v-list-item--link .v-icon {
  margin-right: 0;
}
</style>

<script>
import userApi from "@/api/user";
import serviceApi from "@/api/service";
import tokenService from "@/services/token";
export default {
  name: "UserList",
  data: () => ({
    listUsers: [],
    listServices: [],
    selectedPermissions: [],
    totalRecords: 0,
    loadingTable: false,
    approveConfirmDialog: false,
    removeConfirmDialog: false,
    permissionsDialog: false,
    userConfirmation: {},
    userPermissions: {},
    loggedUser: null,
  }),
  computed: {
    tableHeaders: function () {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.user.fields.name"),
          align: "start",
          value: "name",
        },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.email"), value: "email" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.cpf"), value: "cpf" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.phone"), value: "phone" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.businessName"), value: "businessName" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.businessCnpj"), value: "businessCnpj" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.status"), value: "status.title" },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions", width: 200, sortable: false },
      ];
    },
  },
  created() {
    this.loadServices();
    // Get Logged User Data
    this.loggedUser = tokenService.getDecodedToken().user;
    // Check if user exists
    if (!this.loggedUser && !this.loggedUser.id) this.$router.push({ name: "Dashboard" });
  },
  methods: {
    loadServices() {
      // Create pagination parameters
      let params = {
        size: 999999,
        sort: "category,asc",
      };
      // Get Users
      serviceApi
        .get(params)
        .then((response) => {
          this.listServices = response.data;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        });
    },
    loadUserTable(options = {}) {
      // Reset preview value
      this.loadingTable = true;
      // Create pagination parameters
      let params = {};
      if (options.page) params.page = options.page - 1;
      if (options.itemsPerPage) params.size = options.itemsPerPage;
      if (options.sortBy && options.sortBy[0]) params.sort = options.sortBy[0] + "," + (options.sortDesc[0] ? "desc" : "asc");
      // Get Users
      userApi
        .get(params)
        .then((response) => {
          this.listUsers = response.data;
          this.totalRecords = response.totalRecords;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    removeUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .delete(userId)
        .then(() => {
          let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
          this.listUsers[updateIndex].status.code = "USU_SUSPENSO";
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.removeUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.removeUser.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.removeConfirmDialog = false;
        });
    },
    approveUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .approveUser(userId)
        .then(() => {
          let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
          this.listUsers[updateIndex].status.code = "USU_ATIVO";
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.approveUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.approveUser.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.approveConfirmDialog = false;
        });
    },
    savePermissions(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      let arrPermissions = this.listServices.filter((element) => this.selectedPermissions.indexOf(element.id) >= 0);
      // Get Users
      userApi
        .saveUserPermissions(userId, arrPermissions)
        .then(() => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.saveUserPermissions.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.saveUserPermissions.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.permissionsDialog = false;
        });
    },
    showPermissionDialog(userObj) {
      if (!userObj.id) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Set User Obj
      this.userPermissions = userObj;
      // Erase Permissions
      this.selectedPermissions = [];
      // Get User Permissions
      userApi
        .getPermissionByUser(userObj.id)
        .then((response) => {
          this.selectedPermissions = response.map((a) => a.id);
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          // Show permissions Dialog
          this.permissionsDialog = true;
        });
    },
    closePermissionDialog() {
      // Hide permissions Dialog
      this.permissionsDialog = false;
      // Set User Obj
      this.userPermissions = {};
      // Erase Permissions
      this.selectedPermissions = [];
    },
  },
};
</script>
