<template>
  <v-row>
    <v-col cols="12" md="6" lg="7">
      <div class="text-h5 mt-4">{{ $vuetify.lang.t("$vuetify.dashboard.sections." + section + ".contentTitle") }}</div>
      <v-skeleton-loader v-if="!listItems" type="table"></v-skeleton-loader>
      <v-data-table
        v-else
        class="stripped-datatable mt-8"
        :headers="tableHeader"
        :items="listItems"
        :items-per-page="15"
        item-key="processNo"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            :class="{ 'primary--text' : selectedProcess === item }"
            @click="clickDashboardButton(item)"
          >
            <v-icon class="ma-0">{{ icon ? icon : "mdi-magnify" }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="6" offset-xl="1" lg="5" xl="4">
      <!-- Anchor element to scroll -->
      <v-card ref="processCard"></v-card>
      <v-card class="mt-4 mb-2" color="blue-grey lighten-5" v-if="selectedProcess">
        <v-card-title class="primary--text">
          <v-icon class="primary--text mr-2" v-if="icon">{{ icon }}</v-icon>
          {{ $vuetify.lang.t("$vuetify.dashboard.sections." + section + ".detailCardTitle") }}
        </v-card-title>
        <v-divider class="primary"></v-divider>
        <!-- eslint-disable-next-line -->
        <v-card-text>
          <v-breadcrumbs
            class="pa-0"
            :items="[
              { text: $vuetify.lang.t('$vuetify.dashboard.sections.' + section + '.title') },
              { text: selectedProcess.service },
              { text: selectedProcess.processInstanceId, class: 'font-weight-bold primary' },
            ]"
          >
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <span :class="item.disabled ? 'grey--text' : 'deep-purple--text'">{{ item.text }}</span>
            </template>
          </v-breadcrumbs>
          <v-row class="py-1 mt-2" no-gutters>
            <v-col class="font-weight-bold" cols="12" md="3" xl="2">{{ $vuetify.lang.t("$vuetify.process.fields.service") }}</v-col>
            <v-col>{{ selectedProcess.service }}</v-col>
          </v-row>
          <v-row class="py-1 mt-2" no-gutters>
            <v-col class="font-weight-bold" cols="12" md="3" xl="2">{{ $vuetify.lang.t("$vuetify.process.fields.nameActivity") }}</v-col>
            <v-col>{{ selectedProcess.nameActivity }}</v-col>
          </v-row>
          <v-row class="py-1 mt-2" no-gutters>
            <v-col class="font-weight-bold" cols="12" md="3" xl="2">{{ $vuetify.lang.t("$vuetify.process.fields.createdAt") }}</v-col>
            <v-col>{{ selectedProcess.createdAt | formatDate }}</v-col>
          </v-row>
          <v-row class="py-1 mt-2" no-gutters>
            <v-col class="font-weight-bold" cols="12" md="3" xl="2">{{ $vuetify.lang.t("$vuetify.process.fields.user") }}</v-col>
            <v-col>{{ selectedProcess.user }}</v-col>
          </v-row>
          <!-- <v-row class="py-1" no-gutters>
            <v-col class="font-weight-bold" cols="12">{{ $vuetify.lang.t("$vuetify.process.fields.notes") }}</v-col>
            <v-col>
              <v-textarea background-color="white" outlined></v-textarea>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            :to="{
              name: 'ViewProcess',
              params: { id: selectedProcess.processInstanceId },
              query: { l: formTitle, a: 'getForm', at: activeTab },
            }"
            color="secondary"
          >
            <v-icon v-if="icon">{{ icon }}</v-icon>
            {{ $vuetify.lang.t("$vuetify.dashboard.sections." + section + ".buttonLabel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "MyPendingsProcessContent",
  data: () => ({
    selectedProcess: null,
  }),
  props: {
    section: String,
    formTitle: String,
    icon: String,
    activeTab: String,
    listItems: {
      type: Array,
      default: null,
    },
  },
  computed: {
    tableHeader: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processInstanceId"), value: "processInstanceId" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.service"), value: "service" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.nameActivity"), value: "nameActivity" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.createdAt"), value: "createdAt" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.user"), value: "user" },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions" },
      ];
    },
  },
  filters: {
    // Update Dates from this component to user's timezone
    formatDate: function (value) {
      if (!value) return ''
      return moment(value).format(process.env.VUE_APP_DATE_FORMAT)
    },
  },
  methods: {
    clearContent() {
      // Clear Selected
      this.selectedProcess = null;
    },
    clickDashboardButton(selectedItem) {
      // Select Clicked Item
      this.selectedProcess = selectedItem;
      // Scroll To Card
      setTimeout(() => this.$emit("scroll-to", this.$refs.processCard.$el.offsetTop), 150);
    },
  },
};
</script>
