<template>
  <div class="pa-4">
    <v-row>
      <v-col class="text-h4" cols="12">{{ $vuetify.lang.t("$vuetify.configuration.titlePl") }}</v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-tabs v-model="configurationTabs" background-color="primary" fixed-tabs dark>
            <v-tab href="#LAYOUT">{{ $vuetify.lang.t("$vuetify.configuration.layoutConfiguration.title") }}</v-tab>
            <v-tab href="#EMAIL">{{ $vuetify.lang.t("$vuetify.configuration.emailConfiguration.title") }}</v-tab>
            <v-tab href="#CONTENT">{{ $vuetify.lang.t("$vuetify.configuration.contentConfiguration.title") }}</v-tab>
            <v-tab href="#SERVICES">{{ $vuetify.lang.t("$vuetify.services.title") }}</v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="configurationTabs">
              <v-tab-item value="LAYOUT">
                <v-form ref="formLayout" @submit="handleLayoutConfigurationSubmit">
                  <v-row>
                    <v-col cols="12">
                      <div class="text-h6">{{ $vuetify.lang.t("$vuetify.configuration.layoutConfiguration.colors") }}</div>
                      <v-divider class="my-4"></v-divider>
                      <v-row>
                        <v-col cols="12" md="6">
                          <VAppColorPicker
                            id="primary"
                            name="primary"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.primary')"
                            v-model="layoutConfiguration.primary"
                          ></VAppColorPicker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <VAppColorPicker
                            id="secondaryColor"
                            name="secondaryColor"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.secondary')"
                            v-model="layoutConfiguration.secondary"
                          ></VAppColorPicker>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-h6">{{ $vuetify.lang.t("$vuetify.configuration.layoutConfiguration.images") }}</div>
                      <v-divider class="my-4"></v-divider>
                      <v-row>
                        <v-col cols="12" md="6">
                          <VAppImageInput
                            id="logoImage"
                            name="logoImage"
                            key="logoImage"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.logoImage')"
                            :helper="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.logoImageHelper', maxFileSizeDisplay)"
                            v-model="layoutConfiguration.logoImage"
                            hide-details="auto"
                          ></VAppImageInput>
                        </v-col>
                        <v-col cols="12" md="6">
                          <VAppImageInput
                            id="bannerImage"
                            name="bannerImage"
                            ref="bannerImage"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.bannerImage')"
                            :helper="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.bannerImageHelper', maxFileSizeDisplay)"
                            v-model="layoutConfiguration.bannerImage"
                            hide-details="auto"
                          ></VAppImageInput>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="EMAIL">
                <v-form ref="formEmail" @submit="handleEmailConfigurationSubmit">
                  <v-row>
                    <v-col cols="12" md="8" lg="9">
                      <VAppTextField
                        v-model="emailConfiguration.smtpServer"
                        name="smtpServer"
                        prepend-inner-icon="mdi-server"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpServer')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                      <VAppTextField
                        v-model="emailConfiguration.smtpPort"
                        name="smtpPort"
                        prepend-inner-icon="mdi-door"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpPort')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <VAppTextField
                        v-model="emailConfiguration.smtpEmail"
                        name="smtpEmail"
                        prepend-inner-icon="mdi-at"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpEmail')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <VAppTextField
                        v-model="emailConfiguration.smtpPassword"
                        name="smtpPassword"
                        prepend-inner-icon="mdi-lock"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpPassword')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <VAppTextField
                        v-model="emailConfiguration.fromEmail"
                        name="fromEmail"
                        prepend-inner-icon="mdi-at"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.fromEmail')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="emailConfiguration.useTls"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.useTls')"
                        true-value="true"
                        false-value="false"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="CONTENT">
                <v-form ref="formContent" @submit="handleContentConfigurationSubmit">
                  <v-row>
                    <v-col cols="12">
                      <VAppTextArea
                        v-model="contentConfiguration.termsConditions"
                        name="termsConditions"
                        :label="$vuetify.lang.t('$vuetify.configuration.contentConfiguration.termsConditions')"
                        hide-details="auto"
                      ></VAppTextArea>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="SERVICES">
                <v-row class="justify-end">
                  <v-col cols="12" md="auto">
                    <v-btn :to="{ name: 'RegisterServices' }" color="primary" depressed right large>
                      <v-icon>mdi-plus</v-icon>{{ $vuetify.lang.t("$vuetify.services.addNewService") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ServiceDataTable></ServiceDataTable>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { enumLayoutConfiguration, enumEmailConfiguration, enumContentConfiguration } from "@/enums/configurationKeys";
import layoutConfigurationApi from "@/api/layoutConfiguration";
import emailConfigurationApi from "@/api/emailConfiguration";
import documentApi from "@/api/document";
import ServiceDataTable from "@/components/datatables/Service";
export default {
  name: "Configuration",
  components: { ServiceDataTable },
  data: () => ({
    configurationTabs: "LAYOUT",
    layoutConfiguration: {
      primary: null,
      secondaryColor: null,
      logoImage: null,
      bannerImage: null,
    },
    emailConfiguration: {
      smtpServer: null,
      smtpPort: null,
      smtpEmail: null,
      smtpPassword: null,
      fromEmail: null,
      useTls: "false",
    },
    contentConfiguration: {
      termsConditions: null,
    },
  }),
  props: {
    activeTab: String,
  },
  computed: {
    routeKey: function () {
      // `this` aponta para a instância Vue da variável `vm`
      return this.$route.name + (this.$route.params.id ? this.$route.params.id : "");
    },
    maxFileSizeDisplay() {
      return process.env.VUE_APP_FILE_SIZE_LIMIT / 1048576;
    },
  },
  async created() {
    // If sent an active tab, show
    if (this.activeTab) this.configurationTabs = this.activeTab;
    // Show Overlay
    this.$emit("control-overlay", true);
    // Get Configuration Colors
    await layoutConfigurationApi
      .get()
      .then((configurationItems) => {
        // Set new colors configuration
        configurationItems.forEach((element) => {
          if (element.code === enumLayoutConfiguration.PRIMARYCOLOR && element.value) this.layoutConfiguration.primary = element.value;
          if (element.code === enumLayoutConfiguration.SECONDARYCOLOR && element.value) this.layoutConfiguration.secondary = element.value;
          if (element.code === enumContentConfiguration.TERMSCONDITIONS && element.value) this.contentConfiguration.termsConditions = element.value;
        });
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
          text: error.message,
        });
      });
    // Get Current SMTP Values
    await emailConfigurationApi
      .get()
      .then((emailConfigurations) => {
        // Atualiza as cores
        emailConfigurations.forEach((element) => {
          if (element.code === enumEmailConfiguration.SMTPSERVER) this.emailConfiguration.smtpServer = element.value;
          if (element.code === enumEmailConfiguration.SMTPPORT) this.emailConfiguration.smtpPort = element.value;
          if (element.code === enumEmailConfiguration.SMTPEMAIL) this.emailConfiguration.smtpEmail = element.value;
          if (element.code === enumEmailConfiguration.SMTPFROM) this.emailConfiguration.fromEmail = element.value;
          if (element.code === enumEmailConfiguration.SMTPTLS) this.emailConfiguration.useTls = element.value;
        });
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.emailConfiguration.errors.get"),
          text: error.message,
        });
      })
      .finally(() => {
        // Hide Overlay
        this.$emit("control-overlay", false);
      });
  },
  methods: {
    async handleLayoutConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Colors
      if (this.layoutConfiguration.primary)
        await this.updateLayoutColor(enumLayoutConfiguration.PRIMARYCOLOR, this.layoutConfiguration.primary, "primary", errorControl);
      if (this.layoutConfiguration.secondary)
        await this.updateLayoutColor(enumLayoutConfiguration.SECONDARYCOLOR, this.layoutConfiguration.secondary, "secondary", errorControl);
      // Upload Images
      if (this.layoutConfiguration.logoImage)
        await this.updateDocument(enumLayoutConfiguration.LOGOIMG, this.layoutConfiguration.logoImage, "logoImage", errorControl);
      if (this.layoutConfiguration.bannerImage)
        await this.updateDocument(enumLayoutConfiguration.BANNERIMG, this.layoutConfiguration.bannerImage, "bannerImage", errorControl);
      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.layoutConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.layoutConfiguration.notifications.success.message"),
        });
      }
      // Reload Page after 3 minutes
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    async handleEmailConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Colors
      if (this.emailConfiguration.smtpServer)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPSERVER, this.emailConfiguration.smtpServer, "smtpServer", errorControl);
      if (this.emailConfiguration.smtpPort)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPPORT, this.emailConfiguration.smtpPort, "smtpPort", errorControl);
      if (this.emailConfiguration.smtpEmail)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPEMAIL, this.emailConfiguration.smtpEmail, "smtpEmail", errorControl);
      if (this.emailConfiguration.smtpPassword)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPPASS, this.emailConfiguration.smtpPassword, "smtpPassword", errorControl);
      if (this.emailConfiguration.fromEmail)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPFROM, this.emailConfiguration.fromEmail, "fromEmail", errorControl);
      if (this.emailConfiguration.useTls)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPTLS, this.emailConfiguration.useTls, "useTls", errorControl);
      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.message"),
        });
      }
      // Show Overlay
      this.$emit("control-overlay", false);
    },
    async handleContentConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Colors
      if (this.contentConfiguration.termsConditions)
        await this.updateContentConfiguration(
          enumContentConfiguration.TERMSCONDITIONS,
          this.contentConfiguration.termsConditions,
          "termsCondition",
          errorControl
        );
      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.message"),
        });
      }
      // Show Overlay
      this.$emit("control-overlay", false);
    },
    async updateLayoutColor(code, value, message) {
      await layoutConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors." + message),
          text: error.message,
        });
      });
    },
    async updateDocument(code, value, errorMessage, errorControl) {
      await documentApi.upload(value, value.name, code).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async updateEmailConfiguration(code, value, errorMessage, errorControl) {
      await emailConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.emailConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async updateContentConfiguration(code, value, errorMessage, errorControl) {
      await layoutConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.contentConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
  },
};
</script>
