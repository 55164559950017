<template>
  <v-row>
    <v-col cols="12" class="text-h5 mt-4">
      {{ $vuetify.lang.t("$vuetify.dashboard.sections.dashboard.contentTitle") }}
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" lg="12" xl="12">
      <v-skeleton-loader v-if="!listItems" type="text@3"></v-skeleton-loader>
      <div v-else-if="listItems.length <= 0">
        <v-alert border="left" color="info" dark v-text="$vuetify.lang.t('$vuetify.dashboard.sections.dashboard.noContentsAvailable')"></v-alert>
      </div>
      <div v-else>
        <v-row>
          <v-col v-for="(item, index) in listItems" :key="index" :id="item.name" cols="12" md="3">
            <a v-if="item.status" :href="item.link" target="_blank">
              <v-img :src="item.logoPath"></v-img> 
            </a>
            <v-col v-text="item.name" cols="12" class="text-h5 mt-4" align="center"></v-col>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.description {
  white-space: break-spaces;
}
</style>

<script>
export default {
  name: "NewRequestContent",
  data: () => ({
    listItems: null,
    selectedService: null,
  }),
  computed: {},
  created() {
    // Load Services
    this.loadServices();
  },
  methods: {
    loadServices() {

      // systems
      var sys1 = {
        name: 'SISTEMA A',
        logoPath: require('@/assets/system_img/a.jpg'),
        link: 'https://google.com.br', 
        status: true,
      };

      var sys2 = {
        name: 'SISTEMA B',
        logoPath: require('@/assets/system_img/b.jpg'),
        link: 'https://google.com.br', 
        status: true,
      };

      var sys3 = {
        name: 'SISTEMA C',
        logoPath: require('@/assets/system_img/c.jpg'),
        link: 'https://google.com.br', 
        status: true,
      };

      var sys4 = {
        name: 'SISTEMA D',
        logoPath: require('@/assets/system_img/d.jpg'),
        link: 'https://google.com.br', 
        status: true,
      };

      var sys5 = {
        name: 'SISTEMA E',
        logoPath: require('@/assets/system_img/e.jpg'),
        link: 'https://google.com.br', 
        status: true,
      };

      // Get Services
      this.listItems = []; 
      this.listItems.push(sys1, sys2, sys3, sys4, sys5);
    },
  },
};
</script>
