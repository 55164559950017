<template>
  <v-container fluid>
    <!--
    <v-row>
      <v-col class="text-h4" cols="12">{{ $vuetify.lang.t("$vuetify.dashboard.title") }}</v-col>
    </v-row>
    -->
    <v-tabs class="mt-4" v-model="dashboardTabs" background-color="primary" grow show-arrows center-active dark active-class="secondary">
      <v-tab href="#DASHBOARD">{{ $vuetify.lang.t("$vuetify.dashboard.sections.dashboard.title") }}</v-tab>
      <v-tab href="#NEW_REQUEST">{{ $vuetify.lang.t("$vuetify.dashboard.sections.newRequest.title") }}</v-tab>
      <v-tab href="#MY_PENDINGS">
        {{ $vuetify.lang.t("$vuetify.dashboard.sections.myPendings.title") }}
        <v-badge
          v-if="countPedingProcess"
          color="white"
          :content="countPedingProcess"
          inline
        ></v-badge>
      </v-tab>
      <v-tab href="#MY_PROGRESS" @click="clearTabs">
        {{ $vuetify.lang.t("$vuetify.dashboard.sections.inProgressRequests.title") }}
        <v-badge
          v-if="countInProcessProcess"
          color="white"
          :content="countInProcessProcess"
          inline
        ></v-badge>
      </v-tab>
      <v-tab href="#CLOSED_REQUEST" @click="clearTabs">
        {{ $vuetify.lang.t("$vuetify.dashboard.sections.closeRequests.title") }}
        <v-badge
          v-if="countClosedProcess"
          color="white"
          :content="countClosedProcess"
          inline
        ></v-badge>
      </v-tab>
      <!-- <v-tab href="#NOTIFICATIONS">
        <v-badge color="white" content="1" inline>{{ $vuetify.lang.t("$vuetify.dashboard.sections.notifications.title") }}</v-badge>
      </v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="dashboardTabs">

      <v-tab-item value="DASHBOARD">
        <Systems></Systems>
      </v-tab-item>

      <v-tab-item value="NEW_REQUEST">
        <NewRequestContent ref="NEW_REQUEST" @scroll-to="scrollTo"></NewRequestContent>
      </v-tab-item>
      <v-tab-item value="MY_PENDINGS">
        <ProcessContent ref="MY_PENDINGS" activeTab="MY_PENDINGS" section="myPendings" icon="mdi-email-receive" formTitle="answer" :listItems="listMyPendings" @scroll-to="scrollTo"></ProcessContent>
      </v-tab-item>
      <v-tab-item value="MY_PROGRESS">
        <ProcessContent ref="MY_PROGRESS" activeTab="MY_PROGRESS" section="inProgressRequests" icon="mdi-email-search" :listItems="listMyInProgressRequests" @scroll-to="scrollTo"></ProcessContent>
      </v-tab-item>
      <v-tab-item value="CLOSED_REQUEST">
        <ProcessContent ref="CLOSED_REQUEST" activeTab="CLOSED_REQUEST" section="closeRequests" icon="mdi-email-search" :listItems="listMyCloseRequests" @scroll-to="scrollTo"></ProcessContent>
      </v-tab-item>
      <!-- <v-tab-item value="NOTIFICATIONS">
        <v-row>
          <v-col cols="12" lg="9" xl="7">
            <div class="text-h5 mt-4">{{ $vuetify.lang.t("$vuetify.dashboard.sections.notifications.myNotifications") }}</div>
            <v-list class="mt-8 pt-0" two-line>
              <v-list-item-group v-model="selectedNotification" active-class="primary--text">
                <template v-for="(item, index) in listNotifications">
                  <v-list-item :key="item.title" :class="item.read ? 'grey lighten-4' : ''">
                    <template v-slot:default="{}">
                      <v-list-item-content>
                        <v-list-item-title :class="item.read ? 'grey--text' : ''" v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.date"></v-list-item-action-text>
                        <v-icon v-if="!item.read" color="grey lighten-1">mdi-email</v-icon>
                        <v-icon v-else color="grey lighten-1">mdi-email-open-outline</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider v-if="index < listNotifications.length - 1" :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" offset-xl="1" lg="3" xl="4">
            <v-card class="mt-4" color="blue-grey lighten-5">
              <v-card-title class="blue--text">Título 1</v-card-title>
              <v-divider></v-divider>
              <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vulputate scelerisque nisi, in suscipit purus ullamcorper a. Fusce ut velit quis tellus interdum bibendum id quis orci. Nulla tempor nibh nec ex tincidunt dapibus. Mauris tempus, velit ut tempor condimentum, arcu erat placerat massa, in consequat est odio non leo. Donec egestas ultricies risus sit amet porttitor. Cras imperdiet est eu orci cursus interdum elementum ut nibh. Cras ac neque sapien. Ut maximus finibus eros, vel aliquet ex gravida eget. Nunc hendrerit ipsum nisi, in consequat erat pretium quis.Proin a finibus orci. Proin enim lacus, placerat id cursus vel, sollicitudin at tortor. In mollis metus eu consectetur consequat. Curabitur cursus sapien iaculis dolor semper, at congue quam iaculis. Duis aliquet sed tortor a posuere. Donec ac eros ipsum. Nunc vel lacus mi. Duis dapibus feugiat felis et mollis. Fusce posuere arcu nunc, pretium molestie diam luctus eu.Nulla sit amet leo ultricies, fringilla augue vel, viverra ipsum. Sed ante risus, aliquam a lorem ut, eleifend gravida ex. Integer laoreet enim lectus, at rhoncus lorem euismod non. Sed quis purus purus. Donec ac nibh lacus. Vestibulum lacinia ante et augue tristique, lacinia eleifend odio venenatis. Aliquam id ullamcorper libero. Vestibulum sollicitudin nec odio ut interdum.</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item> -->
    </v-tabs-items>
  </v-container>
</template>
<script>
import lecomApi from "@/api/lecom";
import Systems from "@/components/content/dashboard/Systems";
import NewRequestContent from "@/components/content/dashboard/NewRequestContent";
import ProcessContent from "@/components/content/dashboard/ProcessContent";
export default {
  name: "Dashboard",
  components: { Systems, NewRequestContent, ProcessContent },
  data: () => ({
    dashboardTabs: "DASHBOARD",
    listMyPendings: null,
    countPedingProcess: null,
    listMyInProgressRequests: null,
    countInProcessProcess: null,
    listMyCloseRequests: null,
    countClosedProcess: null,
    listNotifications: [
      {
        title: "Título 1",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: false,
        date: "10/06/2021 10:00hrs",
      },
      {
        title: "Título 2",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: false,
        date: "10/06/2021 10:00hrs",
      },
      {
        title: "Título 3",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: true,
        date: "10/06/2021 10:00hrs",
      },
      {
        title: "Título 4",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales mauris in magna scelerisque tincidunt. Praesent nec lectus ligula. Etiam placerat sodales dignissim. Aenean posuere, arcu eget pharetra mollis, est purus fringilla felis, non porttitor ex massa eu urna. Curabitur ut tempus nunc, non porta velit. Ut in mi posuere, sodales tortor eu, tristique risus. Nam laoreet ligula mollis odio feugiat, pharetra pretium risus consectetur. Aenean ac metus dictum, porta mi ac, ultrices nibh. Ut facilisis porta tincidunt. Nulla facilisi. Aenean malesuada leo quis turpis vehicula lobortis eget ut lorem. Aenean justo leo, faucibus sit amet sollicitudin vel, pharetra et leo. Quisque dictum mauris a libero pretium varius. Mauris euismod diam erat, in consectetur nunc efficitur vitae. Sed sed mi dolor.",
        read: true,
        date: "10/06/2021 10:00hrs",
      },
    ],
    selectedNotification: 0,
  }),
  props: {
    activeTab : {
      type: String,
      default: "DASHBOARD",
    },
  },
  computed: {
    myPendingHeaders: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processNo"), value: "processNo" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.process"), value: "process" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.step"), value: "step" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.deadline"), value: "deadline" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.responsible"), value: "responsible" },
      ];
    },
    myInProgressRequestsHeaders: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processNo"), value: "processNo" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.process"), value: "process" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.step"), value: "step" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.responsible"), value: "responsible" },
      ];
    },
    myClosedRequestsHeaders: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processNo"), value: "processNo" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.process"), value: "process" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.step"), value: "step" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.responsible"), value: "responsible" },
      ];
    },
  },
  created() {
    // Check active Tab
    this.dashboardTabs = this.activeTab;
    // Get Pending Process
    this.loadPendingProcess();
    // Get Progress Process
    this.loadInProcessProcess();
    // Get Closed Process
    this.loadClosedProcess();
  },
  methods: {
    loadPendingProcess() {
      // Get Services
      lecomApi
        .getPending()
        .then((response) => {
          // Get List Itens
          this.listMyPendings = response;
          // Get Number of Pending Rows
          this.countPedingProcess = response.length;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getPending"),
            text: error.message,
          });
        });
    },
    loadInProcessProcess() {
      // Get Services
      lecomApi
        .getProgress()
        .then((response) => {
          // Get List Itens
          this.listMyInProgressRequests = response;
          // Get Number of Pending Rows
          this.countInProcessProcess = response.length;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getProgress"),
            text: error.message,
          });
        });
    },
    loadClosedProcess() {
      // Get Services
      lecomApi
        .getDone()
        .then((response) => {
          // Get List Itens
          this.listMyCloseRequests = response;
          // Get Number of Pending Rows
          this.countClosedProcess = response.length;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getDone"),
            text: error.message,
          });
        });
    },
    clearTabs() {
      if (this.$refs.NEW_REQUEST) this.$refs.NEW_REQUEST.clearContent();
      if (this.$refs.MY_PENDINGS) this.$refs.MY_PENDINGS.clearContent();
      if (this.$refs.MY_PROGRESS) this.$refs.MY_PROGRESS.clearContent();
      if (this.$refs.CLOSED_REQUEST) this.$refs.CLOSED_REQUEST.clearContent();
    },
    scrollTo(top) {
      this.$emit("scroll-to", top);
    },
  },
};
</script>
