import HttpClient from "@/services/httpClient.js";
const apiPath = "/category/";
export default {
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
  getById(categoryId) {
    if (!categoryId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + categoryId,
    }).then((response) => response.data);
  },
  create(categoryObj) {
    if (!categoryObj) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath,
      data: categoryObj,
    }).then((response) => response.data);
  },
  update(categoryObj) {
    if (!categoryObj) return Promise.reject({ message: "error" });
    if (!categoryObj.id) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + categoryObj.id,
      data: categoryObj,
    }).then((response) => response.data);
  },
  delete(categoryId) {
    if (!categoryId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "DELETE",
      url: apiPath + categoryId,
    }).then((response) => response.data);
  },
};
