import HttpClient from "@/services/httpClient.js";
import tokenService from "@/services/token";
const apiPath = "/authentication";
export default {
  authenticate(credentials) {
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath + "/login",
      data: {
        email: credentials.email,
        password: credentials.password,
      },
    })
      .then((response) => response.data)
      .then((response) => {
        // Save token to Local Storage
        tokenService.saveToken(response.token);
      });
  },
  resetPassword(email) {
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath + "/forgot",
      data: {
        email: email,
      },
    });
  },
};
