var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"stripped-datatable elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.listItems,"items-per-page":15,"server-items-length":_vm.totalRecords,"loading":_vm.loadingTable,"options":{ sortBy: ['title'], sortDesc: [false] },"footer-props":{ itemsPerPageOptions: [5, 10, 15, 25, 50] }},on:{"update:options":_vm.getTableItems},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'RegisterServices', params: { id: item.id } },"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.services.editService")))])]),(item.active)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red","small":"","icon":"","disabled":_vm.disableButton},on:{"click":function($event){return _vm.toggleService(item, 0)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.services.disableService")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue","small":"","icon":"","disabled":_vm.disableButton},on:{"click":function($event){return _vm.toggleService(item, 1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.services.enableService")))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }