<template>
  <v-app-bar app color="primary">
    <router-link :to="{ name: 'Dashboard' }">
      <v-img alt="Techs" class="shrink mr-2" :src="logoPath" transition="scale-transition" height="60px" max-width="200px" contain />
    </router-link>
    <v-spacer></v-spacer>
    <div>
      <v-row justify="end" no-gutters>
        <v-col cols="auto">
          <v-menu offset-y v-if="userToken">
            <template v-slot:activator="{ on }">
              <v-btn dark text v-on="on" iconAfter="mdi-menu-down">
                <v-icon dark>mdi-account</v-icon>{{ userInfo.name }}<v-icon dark>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1 font-weight-bold">{{ userInfo.name }}</v-list-item-title>
                  <v-list-item-title class="text-body-2 font-weight-light">{{ userInfo.businessName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.user.lastLogin") }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ userInfo.lastLogin | formatDateTime }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :to="{ name: 'MyAccount' }">
                <v-list-item-action><v-icon>mdi-account</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.user.myAccount") }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'ListUsers' }" v-if="userInfo.admin">
                <v-list-item-action><v-icon>mdi-account-group</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.user.titlePl") }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'Configuration' }" v-if="userInfo.admin">
                <v-list-item-action><v-icon>mdi-cog</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.configuration.titlePl") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleLogoff">
                <v-list-item-action><v-icon>mdi-logout</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.auth.logout") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="auto" class="body-2 white--text">{{ dateNow }}</v-col>
      </v-row>
    </div>
  </v-app-bar>
</template>

<script>
import tokenService from "@/services/token";
import moment from "moment";
moment.locale("pt-br");
export default {
  name: "AppBar",
  data: () => ({
    userInfo: {},
  }),
  computed: {
    userToken: () => {
      return tokenService.getToken();
    },
    logoPath: () => {
      return process.env.VUE_APP_LOGO_PATH;
    },
    dateNow: () => {
      return moment().local().format(process.env.VUE_APP_DATEFULL_FORMAT);
    },
  },
  filters: {
    // Update Dates from this component to user's timezone
    formatDateTime: function (value) {
      if (!value) return "";
      return moment(value).local().format(process.env.VUE_APP_DATETIME_FORMAT);
    },
  },
  created() {
    if (this.userToken) this.userInfo = tokenService.getDecodedToken().user;
  },
  methods: {
    handleLogoff() {
      // Remove Token
      tokenService.removeToken();
      // Redirect to Login
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
