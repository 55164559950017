<template>
  <div class="pa-4 iframe-container">
    <div class="text-h4">{{ $vuetify.lang.t("$vuetify.process." + titleLabel) }}</div>
    <v-skeleton-loader v-if="!lecomFormUrl" type="text@3"></v-skeleton-loader>
    <iframe
      v-else
      name="bpm-iframe"
      id="bpm-iframe"
      class="iframe-element"
      :src="lecomFormUrl"
      frameborder="0"
      width="100%"
      height=""
      allow="encrypted-media"
      scrolling="yes"
    ></iframe>
  </div>
</template>

<script>
import lecomApi from "@/api/lecom";
export default {
  name: "ServiceRegister",
  data: () => ({
    lecomFormUrl: null,
  }),
  props: {
    titleLabel: {
      type: String,
      default: "view",
    },
    action: {
      type: String,
      default: "postOpenProcess",
    },
    activeTab : {
      type: String,
      default: null,
    },
  },
  mounted() {
    // Show Overlay
    this.$emit("control-overlay", true);
    // Check if is editing user
    if (this.$route.params.id) {
      // Get Lecom Form
      lecomApi[this.action](this.$route.params.id)
        .then((response) => {
          this.lecomFormUrl = response.url;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors." + this.action),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    } else {
      // Redirect to Index
      this.$router.push({ name: "Dashboard" }, () => {
        // Hide Overlay
        this.$emit("control-overlay", false);
        // Notify Success
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.process.notifications.error.initProcess.title"),
          text: this.$vuetify.lang.t("$vuetify.process.notifications.error.initProcess.message"),
        });
      });
    }
    // Add Iframe Listener
    window.addEventListener('message', this.receiveMessage);
  },
  methods: {
    receiveMessage (event) {
      switch(event.data.event) {
      case "PROCESS_FORM_SUBMIT_SUCCESS":
        if (this.activeTab) this.$router.push({ name: "Dashboard", query: { at: this.activeTab }});
        else this.$router.push({ name: "Dashboard" });
        break;
      }
    },
  },
  beforeDestroy () {
    // Destroi Iframe Listener
    window.removeEventListener('message', this.receiveMessage)
  }
};
</script>

<style scoped>
.iframe-container {
  height: 100%;
}
.iframe-element {
  width: 100%;
  height: calc(100% - 40px);
  border: 0;
}
iframe {
  width: 100%;
}
</style>
