<template>
  <v-btn class="secondary py-7" width="100%" dark large v-bind="$attrs" v-on="$listeners">
    <v-icon v-if="icon">mdi-{{ icon }}</v-icon>
    {{ text }}
  </v-btn>
</template>
<script>
export default {
  // Disable automatic attribute inheritance, so that $attrs are
  // passed to the <input>, even if it's not the root element.
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
