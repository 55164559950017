const enumLayoutConfiguration = {
  PRIMARYCOLOR: "PRIMARY_COLOR",
  SECONDARYCOLOR: "SECONDARY_COLOR",
  LOGOIMG: "LOGO_IMG",
  BANNERIMG: "BANNER_IMG",
};
const enumEmailConfiguration = {
  SMTPSERVER: "SMTP",
  SMTPPORT: "SMTP_PORT",
  SMTPEMAIL: "SMTP_EMAIL",
  SMTPPASS: "SMTP_PASS",
  SMTPFROM: "SMTP_FROM",
  SMTPTLS: "SMTP_START_TLS",
};
const enumContentConfiguration = {
  TERMSCONDITIONS: "TERMS_CONDITION",
};
export { enumLayoutConfiguration, enumEmailConfiguration, enumContentConfiguration };
