<template>
  <v-main>
    <v-row class="admin-content-panel" no-gutters>
      <v-col class="content-scroll" ref="scrollableContent">
        <router-view :key="routeKey" @control-overlay="controlLoadingOverlay" @scroll-to="scrollContent"></router-view>
      </v-col>
    </v-row>
    <VAppLoadingOverlay v-model="showOverlay" />
  </v-main>
</template>

<script>
export default {
  name: "Default",
  data: () => ({
    showOverlay: false,
  }),
  computed: {
    routeKey: function () {
      // `this` aponta para a instância Vue da variável `vm`
      return this.$route.name + (this.$route.params.id ? this.$route.params.id : "");
    },
  },
  methods: {
    controlLoadingOverlay(flag) {
      this.showOverlay = flag;
    },
    scrollContent(top) {
      this.$refs.scrollableContent.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    },
  },
};
</script>
