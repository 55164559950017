import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import routes from "@/router/routes";
import tokenService from "@/services/token";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Middleware
router.beforeEach(async (to, from, next) => {
  // Check if is admin and user is logged
  const OnlyLogged = to.matched.some((record) => record.meta.OnlyLogged);
  const OnlyLoggedOut = to.matched.some((record) => record.meta.OnlyLoggedOut);
  const loggedIn = !!tokenService.getToken();
  if (OnlyLogged && !loggedIn) {
    return next({
      name: "Login",
      //query: { redirect: to.fullPath },
    });
  }
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && OnlyLoggedOut) {
    return next({
      name: "Dashboard",
    });
  }
  // Check if token not expired
  if (loggedIn) {
    const loggedToken = tokenService.getDecodedToken();
    const dt = new Date();
    // Check if token was expired
    if (loggedToken.exp < dt.getTime() / 1000) {
      // Remove the token and remove Authorization header from Api Service as well
      tokenService.removeToken();
      // Redirect to Login
      return next({
        name: "Login",
        //query: {redirect: to.fullPath} // Store the full path to redirect the user to after login
      });
    }
  }
  next();
});

export default router;
