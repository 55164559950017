<template>
  <v-layout fill-height align-center>
    <v-row>
      <v-col cols="10" md="6" lg="3" class="offset-1 offset-md-3 offset-lg-8">
        <v-card class="elevation-12 login-card" light>
          <v-card-title class="primary--text justify-center">{{ projectTitle }}</v-card-title>
          <ValidationObserver ref="observerForgot">
            <v-form ref="form" @submit="handleForgot">
              <v-card-text>
                <div class="subtitle-2 mb-3">{{ $vuetify.lang.t("$vuetify.auth.forgotSubTitle") }}</div>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.email')" rules="required|email" v-slot="{ errors }">
                  <VAppTextField
                    v-model="forgotEmail"
                    name="forgotEmail"
                    prepend-inner-icon="mdi-account"
                    :label="$vuetify.lang.t('$vuetify.auth.email')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'Login' }" color="primary" text large exact>
                  {{ $vuetify.lang.t("$vuetify.buttons.back") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" depressed right large>
                  {{ $vuetify.lang.t("$vuetify.buttons.send") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import authenticationApi from "@/api/authentication";

export default {
  name: "Forgot",
  metaInfo: {
    title: "Esqueci minha senha",
  },
  data: () => ({
    forgotEmail: "",
  }),
  computed: {
    projectTitle: () => {
      return process.env.VUE_APP_TITLE;
    },
  },
  methods: {
    // Login function
    handleForgot(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.observerForgot.validate().then((success) => {
        if (success) {
          authenticationApi
            .resetPassword(this.forgotEmail)
            .then(() => {
              this.$notify({
                group: "application",
                type: "success",
                title: this.$vuetify.lang.t("$vuetify.auth.notifications.success.forgotReset.title"),
                text: this.$vuetify.lang.t("$vuetify.auth.notifications.success.forgotReset.message"),
              });
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.authentication.errors.forgot"),
                text: error.message,
              });
            })
            .finally(() => {
              // Hide Overlay
              this.$emit("control-overlay", false);
            });
        } else {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverForgot.title"),
            text: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverForgot.message"),
          });
          // Hide Overlay
          this.$emit("control-overlay", false);
        }
      });
    },
  },
};
</script>
